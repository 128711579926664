<template>
  <div v-if="!!page">
    <CmsB2bHero v-if="page.type === PageType.B2bSeite" :hero="page.hero" />
    <CmsWasteHero v-if="page.type === PageType.Abfallunterseite" :hero="page.hero" />
    <CmsHero
      v-if="page.type === PageType.Stadtseite"
      :title1="page.hero?.title || ''"
      :title2="''"
      :bg="page.hero?.background_image || null"
      :mobile-background="page.hero?.mobile_background || null"
      :hero-hint-label="page.hero?.hint_label || null"
      :hero-hint-text="page.hero?.hint_text || null"
      :hero-hint-mobile-text="page.hero?.hint_mobile_text || null"
      :claims="page.hero?.claims || []" />

    <CmsContent
      v-for="content in page.contents"
      :id="content.content_id"
      :key="content.content_id"
      :content-id="content.content_id" />

    <OrderNow :order-now="page.order_now_content" />
  </div>
  <PageNotFound v-else />

  <GlobalSidebar :title="t('cart')" :persistent="true">
    <SidebarCartSummary v-if="currentGlobalSidebarType === ACTIVE_SIDEBAR_TYPE.CART" />
  </GlobalSidebar>
</template>

<script setup lang="ts">
import {
  ACTIVE_SIDEBAR_TYPE,
  cityList,
  DirectusModule,
  pageFieldsFilter,
  PageType,
} from '~/types/enums'
import type { Page } from '~/types/types'

const { $directus, $readItem, $preview } = useNuxtApp()
const route = useRoute()
const offerStore = useOfferStore()
const pageSlug = route.params.slug as string
const sidebarStore = useSidebarStore()
const { currentGlobalSidebarType } = storeToRefs(sidebarStore)
const { t } = useI18n()

const paidCart = useCookie('paidCart')
paidCart.value = undefined

const { data } = await useAsyncData(() => {
  const query = $preview ? {} : ({ status: 'published' } as any)
  return $directus.request(
    $readItem(DirectusModule.Page as any, pageSlug.toLowerCase(), {
      filter: query,
      fields: pageFieldsFilter,
    }),
  )
})

const page = data.value as unknown as Page

if (!page) {
  offerStore.reset()

  if (cityList.includes(pageSlug)) {
    await navigateTo('/')
  }
}

useHead({
  link: [
    {
      rel: 'canonical',
      href: `https://containerdienst.de/${pageSlug}`,
    },
  ],
})
const cartStore = useCartStore()
cartStore.getCart()

if (page?.meta_title && page?.meta_description) {
  useSeoMeta({
    title: page.meta_title || '',
    description: page.meta_description || '',
  })
}
</script>
